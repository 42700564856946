
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-slate-100;
  @apply text-indigo-950;
  @apply md:max-w-screen-2xl;
  @apply md:mx-auto;
}